import React from "react";
import { graphql } from "gatsby";
import { Date, RichText } from "prismic-reactjs";
import SEO from "../components/seo/seo";
import Layout from "../components/layout/layout";
import { Quote, Text } from "../components/slices";
import IsVisible from "react-is-visible";
import Link from "gatsby-plugin-transition-link";
import Icon from "../components/icon/icon";
import { JSONLD, Generic, Author } from "react-structured-data";

export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismic {
      allBlogposts(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              tags
              type
              firstPublicationDate
              lastPublicationDate
            }
            body {
              __typename
              ... on PRISMIC_BlogpostBodyText {
                primary {
                  textcontent
                }
                type
              }
              ... on PRISMIC_BlogpostBodyQuote {
                primary {
                  author
                  quote
                }
                type
              }
            }
            icon
            metatitle
            metadescription
            summary
            title
            author
            date
            image
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

// Sort and display the different slice options
const ArticleSlices = ({ slices }) => {
  if (!slices) return null;
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.type) {
        case "text":
          return <div key={index}>{<Text slice={slice} />}</div>;
        case "quote":
          return <div key={index}>{<Quote slice={slice} />}</div>;
      }
    })();
    return res;
  });
};

// Display the title and content of the article
const ArticleBody = ({ doc, siteUrl }) => {
  let postDate = Date(doc.date);
  let categoryName = doc._meta.tags[0];
  postDate = postDate
    ? new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }).format(postDate)
    : "";
  return (
    <div className="c-content c-content--blog-post">
      <div className="container-fluid">
        <div className="row">
          <div className="c-content__container">
            <IsVisible once>
              {(isVisible) => (
                <div
                  className={`c-content__icon${isVisible ? " is-visible" : ""}`}
                >
                  <Icon icon={doc.icon}></Icon>
                </div>
              )}
            </IsVisible>
            <h1 className="c-content__title">{RichText.asText(doc.title)}</h1>
            {/* Go through the slices of the post and render the appropriate one */}
            <ArticleSlices slices={doc.body} />
          </div>
          <div className="c-content__aside">
            {categoryName && (
              <div className="c-content__aside-item">
                <div className="c-content__aside-title">Category</div>
                <div className="c-content__aside-content">
                  <Link
                    to="/blog"
                    entry={{
                      delay: 0.5,
                      length: 0,
                      state: {
                        category: categoryName,
                      },
                    }}
                    exit={{ length: 0.5 }}
                  >
                    {categoryName}
                  </Link>
                </div>
              </div>
            )}
            <div className="c-content__aside-item">
              <div className="c-content__aside-title">Date Written</div>
              <div className="c-content__aside-content">{postDate}</div>
            </div>
            {doc.author && (
              <div className="c-content__aside-item">
                <div className="c-content__aside-title">Author</div>
                <div className="c-content__aside-content">
                  {RichText.asText(doc.author)}
                </div>
              </div>
            )}
            <div className="c-content__aside-item">
              <div className="c-content__aside-title">Share</div>
              <div className="c-content__aside-content">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}/blog/${doc._meta.uid}`}
                  target="_blank"
                  rel="noopener"
                  className="c-content__aside-social-link"
                >
                  <svg fill="none" viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.3933 15.9999V8.7015h2.4498l.3667-2.8442H9.3933v-1.816c0-.8235.2287-1.3847 1.4097-1.3847l1.5061-.0008V.112C12.0485.0774 11.1545 0 10.1144 0 7.9428 0 6.4561 1.3255 6.4561 3.7597v2.0976H4v2.8443h2.456V16l2.9373-.0001z"
                      fill="#fff"
                    />
                  </svg>
                  <span className="u-visually-hide">Share on Facebook</span>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${siteUrl}/blog/${doc._meta.uid}`}
                  target="_blank"
                  rel="noopener"
                  className="c-content__aside-social-link"
                >
                  <svg fill="none" viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 2.5784a6.412 6.412 0 01-1.8848.5297c.678-.416 1.1983-1.0757 1.4434-1.8616-.6348.3853-1.3364.6655-2.0858.8157C12.8755 1.4083 12.0218 1 11.0771 1 9.2655 1 7.7957 2.5072 7.7957 4.3658c0 .264.0282.52.0846.7666-2.7282-.1406-5.1465-1.4792-6.7665-3.518-.2827.4989-.4442 1.0777-.4442 1.694 0 1.1672.5795 2.1977 1.4604 2.8015-.5372-.0164-1.0444-.1695-1.4876-.4199v.0414c0 1.6314 1.1316 2.9922 2.6342 3.3004-.2751.079-.5653.1184-.865.1184a3.189 3.189 0 01-.6179-.0597c.418 1.3367 1.6294 2.3104 3.0663 2.3364-1.1232.9033-2.5394 1.4416-4.0768 1.4416A6.5731 6.5731 0 010 12.8223c1.4528.9534 3.179 1.511 5.0319 1.511 6.0386 0 9.3396-5.1282 9.3396-9.5765 0-.1463-.0028-.2927-.0084-.4362A6.738 6.738 0 0016 2.5784z"
                      fill="#fff"
                    />
                  </svg>
                  <span className="u-visually-hide">Share on Twitter</span>
                </a>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteUrl}/blog/${doc._meta.uid}`}
                  target="_blank"
                  rel="noopener"
                  className="c-content__aside-social-link"
                >
                  <svg data-name="Group 1282" viewBox="0 0 76.624 65.326">
                    <path
                      data-name="Path 2525"
                      d="M72.864 61.109a1.2 1.2 0 001.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025 1.291 2.029h.724l-1.389-2.1zm-.783-.472h-.785v-1.593h.995c.514 0 1.1.084 1.1.757 0 .774-.593.836-1.314.836"
                      fill="#0a66c2"
                    />
                    <path
                      data-name="Path 2520"
                      d="M55.204 55.204h-9.6V40.17c0-3.585-.064-8.2-4.993-8.2-5 0-5.765 3.906-5.765 7.939v15.294h-9.6V24.287h9.216v4.225h.129a10.1 10.1 0 019.093-4.994c9.73 0 11.524 6.4 11.524 14.726zm-40.79-35.143a5.571 5.571 0 115.57-5.572 5.571 5.571 0 01-5.57 5.572m4.8 35.143h-9.61V24.287h9.61zM59.99.004H4.78a4.728 4.728 0 00-4.781 4.67v55.439a4.731 4.731 0 004.781 4.675h55.21a4.741 4.741 0 004.8-4.675V4.67A4.738 4.738 0 0059.99 0"
                      fill="#0a66c2"
                    />
                    <path
                      data-name="Path 2526"
                      d="M72.163 56.411a4.418 4.418 0 10.085 0h-.085m0 8.33a3.874 3.874 0 113.809-3.938v.065a3.791 3.791 0 01-3.708 3.871h-.1"
                      fill="#0a66c2"
                    />
                  </svg>
                  <span className="u-visually-hide">Share on LinkedIn</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="c-content__footer">
            <div className="c-content__footer-brand-container">
              <Link
                to="/blog"
                className="c-content__footer-brand"
                entry={{ delay: 0.5, length: 0 }}
                exit={{ length: 0.5 }}
              >
                {/* eslint-disable */}
                <svg fill="none" viewBox="0 0 60 60">
                  <path d="M60 49.0908H0v10.9091h60V49.0908z" fill="#828282" />
                  <path
                    d="M60 36.8181H0v10.9091h49.0909C55.1158 47.7272 60 42.843 60 36.8181z"
                    fill="#828282"
                  />
                  <path
                    d="M49.0909 24.5454H0c0 6.0249 4.8842 10.9091 10.9091 10.9091H60c0-6.0249-4.8842-10.9091-10.9091-10.9091z"
                    fill="#828282"
                  />
                  <path
                    d="M60 12.2727H10.9091C4.8841 12.2727 0 17.1569 0 23.1818h60V12.2727z"
                    fill="#828282"
                  />
                  <path d="M60 0H0v10.9091h60V0z" fill="#828282" />
                </svg>
                {/* eslint-enable */}
                <span className="u-visually-hide">ScootScience</span>
              </Link>
            </div>
            <div className="c-content__footer-text">
              <div>&copy; Copyright 2016 – 2022 </div>
              <div>Scoot Science Inc.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-content__close">
        <Link
          to="/blog"
          className="c-content__close-link c-btn"
          entry={{ delay: 0.5, length: 0 }}
          exit={{ length: 0.5 }}
        >
          <svg fill="none" viewBox="0 0 30 31">
            <path
              stroke="#fff"
              strokeWidth="2"
              d="M.9914 1.2929l28.2843 28.2843M28.9914 1.7071L.7071 29.9914"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allBlogposts.edges.slice(0, 1).pop();
  if (!doc) return null;
  const title = RichText.asText(doc.node.title);
  const description = RichText.asText(doc.node.summary);
  const metaTitle = doc.node.metatitle
    ? RichText.asText(doc.node.metatitle)
    : title;
  const metaDescription = doc.node.metadescription
    ? RichText.asText(doc.node.metadescription)
    : description;
  const image = doc.node.image ? doc.node.image.url : "";
  const siteUrl = data.site.siteMetadata.siteUrl;
  const pageUrl = siteUrl + "/blog/" + doc.node._meta.uid;
  const logo = siteUrl + "/images/logo.png";
  const author = doc.node.author
    ? RichText.asText(doc.node.author)
    : "Scoot Science";
  const publishDate = doc.node._meta.firstPublicationDate;
  const modifyDate = doc.node._meta.lastPublicationDate;
  return (
    <Layout headerClass="c-navbar--article" footerClass="c-footer--article">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={`/blog/${doc.node._meta.uid}`}
        image={image}
      />
      <ArticleBody doc={doc.node} siteUrl={siteUrl} />
      <JSONLD dangerouslyExposeHtml>
        <Generic
          type="newsarticle"
          jsonldtype="NewsArticle"
          schema={{
            headline: title,
            image: image,
            datePublished: publishDate,
            dateModified: modifyDate,
          }}
        >
          <Generic
            type="mainEntityOfPage"
            jsonldtype="WebPage"
            schema={{ url: pageUrl }}
          />
          <Author name={author} />
          <Generic
            type="publisher"
            jsonldtype="Organization"
            schema={{ name: "Scoot Science" }}
          >
            <Generic
              type="logo"
              jsonldtype="ImageObject"
              schema={{ url: logo }}
            />
          </Generic>
        </Generic>
      </JSONLD>
    </Layout>
  );
};
